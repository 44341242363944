/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { diff } from 'json-diff';
import moment from 'moment';

import { Obj } from '../models/core-model';

const defineToRadians = (): void => {
  if (!Number.prototype.toRadians) {
    Number.prototype.toRadians = function (): number {
      const num = this as number;
      return (num * Math.PI) / 180;
    };
  }
};

const jsonDifference = (updated: any, initial = {}): Obj => {
  return diff(initial, updated);
};

const dateTimePipe = (data: string | Date): string => {
  const temp = new Date(data);
  const date = temp.toLocaleDateString();
  const time = temp.toLocaleTimeString('en-US');

  return `${date} - ${time}`;
};

const iconSizeSetup = (url: string, width = 48, height = 48) => {
  return { url, scaledSize: { width, height } };
};

const get24HourTime = (date: string | Date) => {
  return moment(date).format('HH:mm:ss');
};

const durationInMinutes = (startTime: Date, endTime: Date): number => {
  const durationInMilliseconds = endTime.valueOf() - startTime.valueOf();
  const totalMinutes = Math.floor(durationInMilliseconds / (1000 * 60));
  return totalMinutes;
};

const timeDuration = (startTime: Date, endTime: Date): string => {
  const totalMinutes = durationInMinutes(startTime, endTime);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return [hours && `${hours}H`, minutes && `${minutes}M`].filter(Boolean).join(', ');
};

const generateEncodedURI = (value: string[]) => {
  return encodeURIComponent(JSON.stringify(value));
};

const decodeArrayFromURL = (value: string) => {
  const decodedString = decodeURIComponent(value);
  return decodedString
    .replace(/^\[|\]$/g, '')
    .split(',')
    .map((name) => name.trim().replace(/^"|"$/g, ''));
};

export {
  defineToRadians,
  jsonDifference,
  dateTimePipe,
  iconSizeSetup,
  get24HourTime,
  timeDuration,
  durationInMinutes,
  generateEncodedURI,
  decodeArrayFromURL,
};
