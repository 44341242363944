import { AxiosInstance, AxiosResponse } from 'axios';

import { APIResponse } from '../models/common-model';
import { Session } from '../models/session-model';
import { SessionCollection } from '../models/session-model';
import { store } from '../store/store';

interface SessionService {
  getDashboardGrid: () => Promise<Session[]>;
  getActiveSessionByRouteId: (sessionId: string) => Promise<APIResponse<Session>>;
  getSessionList: () => Promise<SessionCollection[]>;
  getActiveSessionList: () => Promise<Session[]>;
  deleteSessions: () => Promise<APIResponse>;
  stopSession: (sessionId: string, data: { reason: string }) => Promise<APIResponse>;
  invalidateSession: (sessionId: string, data: { reason: string }) => Promise<APIResponse>;
}
interface DeleteResponse {
  db: boolean;
}

const SessionServices = (httpClient: AxiosInstance): SessionService => {
  return {
    getDashboardGrid: async () => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        const authData = state.auth;
        if (!selectedTrust || !authData || !authData.userDetails) {
          return [];
        }

        const params = { role: authData.userDetails.role, id: selectedTrust._id };
        const res: AxiosResponse<Session[]> = await httpClient.post('/sessions/fetch-active-trip-by-role', params);
        return res.data;
      } catch (err) {
        return [];
      }
    },

    getActiveSessionByRouteId: async (sessionId: string) => {
      try {
        const res: AxiosResponse<APIResponse<Session>> = await httpClient.get('/sessions/active/' + sessionId);
        return res.data;
      } catch (error) {
        return { status: false, message: 'Invalid request' };
      }
    },

    getSessionList: async () => {
      try {
        const res: AxiosResponse<SessionCollection[]> = await httpClient.get('/devices/sessions');
        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    getActiveSessionList: async () => {
      try {
        const res: AxiosResponse<Session[]> = await httpClient.get('/sessions/active');
        return res.data;
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    deleteSessions: async () => {
      const errorResponse: APIResponse = {
        status: false,
      };
      const successResponse: APIResponse = {
        status: true,
      };
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return errorResponse;
        }

        const res: AxiosResponse<DeleteResponse> = await httpClient.delete('/devices/sessions');
        return res.data.db ? successResponse : errorResponse;
      } catch (err) {
        console.log(err);
        return errorResponse;
      }
    },

    stopSession: async (sessionId, data) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return { status: false, message: 'Invalid request' };
        }

        const diff = { ...data };
        const res: AxiosResponse<APIResponse> = await httpClient.put('/sessions/' + sessionId, { ...data, diff });
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Invalid request' };
      }
    },

    invalidateSession: async (sessionId, data) => {
      try {
        const state = store.getState();
        const selectedTrust = state.trust.selectedTrust;
        if (!selectedTrust) {
          return { status: false, message: 'Invalid request' };
        }

        const res: AxiosResponse<APIResponse> = await httpClient.post('/sessions/invalidate/' + sessionId, data);
        return res.data;
      } catch (err) {
        console.log(err);
        return { status: false, message: 'Invalid request' };
      }
    },
  };
};

export default SessionServices;
